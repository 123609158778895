import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from '../images/logo.png';

const Header = ({ siteTitle }) => (
  <header className="main-header fixed bg-gray-100 flex py-6 px-6 text-center w-full">
    <div className="container mx-auto flex items-center justify-between">
      <h1><Link to="/" className="outline-none"><img src={logo} alt={siteTitle} className="mx-auto" /></Link></h1>
      <a className="text-red-700 border rounded border-red-700 hover:bg-red-700 hover:text-white outline-none focus:bg-red-700 focus:text-white p-3 ml-6" href="#pedidos">Pedidos</a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
