/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import telefono from '../images/telefono.svg';
import whatsapp from '../images/whatsapp.svg';
import facebook from '../images/facebook.svg';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const handleEvent = (name) => {
    window.gtag('event', 'click', {
      event_category: 'Telefono',
      event_label: name,
    })
  }

  return (
    <Fragment>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className="p-6 pt-32 md:pt-48">{children}</main>
      <section className="contact-info py-12 px-6">
        <div className="container mx-auto">
          <div className="w-full md:w-2/3 lg:w-1/2 mx-auto bg-white border-4 border-double border-orange-400 p-6 md:px-12 lg:px-24 text-center text-lg">
            <h2 id="pedidos" className="text-4xl font-bold text-red-700 mb-6 uppercase tracking-widest">Pedidos</h2>
            <p className="mb-6">Clicka para ponerte en contacto con nosotros y haz tu pedido, te lo llevaremos a domicilio o puedes pasar a recogerlo por nuestro local.</p>
            <p>Puedes pagar en efectivo o con tarjeta de crédito.</p>
            <a className="transition-colors duration-500 ease-in-out bg-red-700 text-white border-4 mt-6 py-3 px-6 inline-block border-double hover:bg-red-500 flex items-center justify-center" href="tel:+34674353017" onClick={()=>handleEvent('Yasmina')}><img src={telefono} width="24" height="24" className="mr-3" alt="" />Llama al 674 35 30 17</a><br />
            <a className="transition-colors duration-500 ease-in-out bg-red-700 text-white border-4 mt-6 py-3 px-6 inline-block border-double hover:bg-red-500 flex items-center justify-center" href="tel:+34678185075" onClick={()=>handleEvent('Loly')}><img src={telefono} width="24" height="24" className="mr-3" alt="" />Llama al 678 18 50 75</a><br />
            <a className="transition-colors duration-500 ease-in-out bg-green-600 text-white border-4 mt-6 py-3 px-6 inline-block border-double hover:bg-green-900 flex items-center justify-center" href="https://api.whatsapp.com/send?phone=34674353017" onClick={()=>handleEvent('Whatsapp')}><img src={whatsapp} width="24" height="24" className="mr-3" alt="" />Envíanos un WhatsApp</a><br />
            <a className="transition-colors duration-500 ease-in-out bg-blue-700 text-white border-4 mt-6 py-3 px-6 inline-block border-double hover:bg-blue-900 flex items-center justify-center" href="https://m.me/CMiCafe" onClick={()=>handleEvent('Facebook')}><img src={facebook} width="24" height="24" className="mr-3" alt="" /> Envíanos un mensaje de Facebook</a>
          </div>
        </div>
      </section>
      <section className="imagen-te"></section>
      <footer className="bg-red-700 text-center text-white py-12 px-6 w-full">
        <strong>674 35 30 17</strong><br />
        Camino del Pinillo N2 local 2<br />
        29620 Torremolinos (Málaga)<br />
        © {new Date().getFullYear()}
      </footer>
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
